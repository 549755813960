import { RefObject, useEffect } from 'react';

const useClickOutside = (
  ref: RefObject<HTMLDivElement>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callBack: (e?: any) => void,
  exceptionsIds?: string[]
): void => {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleClickOutside = (event: MouseEvent | any) => {
      const triggerId = event.target.getAttribute('id');
      if (exceptionsIds?.length && triggerId && exceptionsIds.includes(triggerId)) {
        return;
      }

      if (ref.current && !ref.current.contains(event.target)) {
        callBack();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callBack]);
};

export default useClickOutside;
