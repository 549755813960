import { UmbracoLanguage } from '@shared/types';

import { ILanguage, ILanguagesFinal } from './models';

const parseLanguagesToArray = (
  urlsArray: string[][],
  languagesArray?: UmbracoLanguage.IStructure[]
): ILanguage[] => {
  const languagesFinal: ILanguagesFinal = {};

  languagesArray?.forEach(({ languageLabel, languageLetters, languageLettersShort }) => {
    languagesFinal[languageLetters] = {
      languageLabel,
      languageLettersShort,
      languageLetters,
    };
  });

  urlsArray.forEach((pageUrl: string[]) => {
    if (languagesFinal[pageUrl[0]]) {
      languagesFinal[pageUrl[0]] = { ...languagesFinal[pageUrl[0]], link: pageUrl[1] };
    }
  });

  return Object.values(languagesFinal);
};

export default {
  parseLanguagesToArray,
};
