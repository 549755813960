import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Footer as FooterNamespace } from '@shared/types';

import Container from 'layout/Container';
import NavigationItems from 'layout/Footer/NavigationItems';
import Logo from 'layout/Logo';
import { HTML_ELEMENTS } from 'utils/constants';
import findByLang from 'utils/findByLang';

import { IPropsFooter } from './models';

import './Footer.scss';

const Footer: FC<IPropsFooter> = ({ brandSettings, currentLang }) => {
  const {
    allFooterType: { nodes: footersNodes },
  } = useStaticQuery<{ allFooterType: { nodes: FooterNamespace.IStructure[] } }>(graphql`
    {
      allFooterType {
        nodes {
          ...FragmentFooter
        }
      }
    }
  `);

  const { copyRight, navigationItems } = footersNodes.find(({ lang }) =>
    findByLang(lang, currentLang)
  )!;

  return (
    <footer className="footer" data-testid="Footer" id={HTML_ELEMENTS.FOOTER_ID}>
      <Container className="footer-container">
        <div className="footer__logo">
          <Logo brandSettings={brandSettings} withoutLink />
        </div>
        <NavigationItems navigationItems={navigationItems} />
        <div className="footer__info">{copyRight}</div>
      </Container>
    </footer>
  );
};

export default Footer;
