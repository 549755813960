import React, { ChangeEvent, FC, FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import { navigate } from 'gatsby';
import useClickOutside from 'hooks/useClickOutside';

import IconCustom from 'components/helpers/IconCustom';
import { ALGOLIA_URL_PARAMS } from 'utils/algoliaFilters/constants';
import { getLocationQueryStringParam } from 'utils/browser';

import { IPropsMobileSearchBar } from './models';

import './MobileSearchBar.scss';

const MobileSearchBar: FC<IPropsMobileSearchBar> = ({
  searchIconFormAriaLabel,
  searchPlaceholder,
  searchRedirect,
  searchTitle,
  closeSearchFormAriaLabel,
  removeSearchFormAriaLabel,
  handleVisibility,
  handleCloseSearchBar,
  clickOutsideExceptionsIds,
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const inputSearchRef = useRef<HTMLInputElement>(null);
  const searchBlockRef = useRef<HTMLDivElement>(null);
  const inputValueChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  }, []);

  const queryUrlParam = getLocationQueryStringParam(ALGOLIA_URL_PARAMS.querySearch, 'string');

  useEffect(() => {
    setInputValue(queryUrlParam);
  }, [queryUrlParam]);

  const removeInputValue = useCallback(() => {
    setInputValue('');
    if (inputSearchRef?.current) {
      inputSearchRef.current.focus();
    }
  }, []);

  const redirectToSearchResultPage = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      handleVisibility();

      navigate(
        encodeURI(`${searchRedirect[0].url}?${ALGOLIA_URL_PARAMS.querySearch}=${inputValue}`)
      );
    },
    [searchRedirect, inputValue]
  );

  useClickOutside(searchBlockRef, handleCloseSearchBar, clickOutsideExceptionsIds);

  return (
    <div data-testid="MobileSearchBar" ref={searchBlockRef} className="mobile-search-bar">
      <form className="mobile-search-bar__form" onSubmit={redirectToSearchResultPage}>
        <input
          ref={inputSearchRef}
          value={inputValue}
          onChange={inputValueChange}
          type="text"
          className="mobile-search-bar__input"
          placeholder={searchPlaceholder}
          title={searchTitle}
          aria-label={searchTitle}
        />
        <button
          aria-label={searchIconFormAriaLabel}
          type="submit"
          className="mobile-search-bar__submit"
        >
          <IconCustom icon="search" className="mobile-search-bar__submit-icon" />
        </button>
        <button
          aria-label={removeSearchFormAriaLabel}
          onClick={removeInputValue}
          type="button"
          className="mobile-search-bar__remove-btn"
        >
          <IconCustom icon="cross" className="mobile-search-bar__remove-btn-icon" />
        </button>
      </form>
      <button
        aria-label={closeSearchFormAriaLabel}
        type="button"
        className="mobile-search-bar__close-btn"
        onClick={handleVisibility}
      >
        <IconCustom icon="cross" className="mobile-search-bar__close-btn-icon" />
      </button>
    </div>
  );
};

export default MobileSearchBar;
