import React, { FC } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import GatsbyImage from 'components/helpers/GatsbyImage';

import { ILogoProps } from './models';

import './Logo.scss';

const Logo: FC<ILogoProps> = ({
  brandSettings: { brandLogoLink, brandLogoLinkAriaLabel, brandLogo },
  className,
  withoutLink,
}) =>
  withoutLink ? (
    <div data-testid="Logo__withoutLink" className={classNames('brand-logo__wrapper', className)}>
      <GatsbyImage className="brand-logo__text" image={brandLogo} />
    </div>
  ) : (
    <Link
      className={classNames('brand-logo__wrapper', className)}
      to={brandLogoLink[0].url}
      aria-label={brandLogoLinkAriaLabel}
      data-testid="Logo"
    >
      <GatsbyImage className="brand-logo__text" image={brandLogo} />
    </Link>
  );

export default Logo;
