export const MOBILE_BREAKPOINT = 375;
export const MOBILE_MIDDLE_BREAKPOINT = 490;
export const TABLET_BREAKPOINT = 768;
export const DESKTOP_BREAKPOINT = 1024;
export const LAYOUT_WRAPPER = 1420;

const HEADER_ID = 'header';
const FOOTER_ID = 'footer';
const CONTENT_ID = 'siteContent';

export const HTML_ELEMENTS = {
  HEADER_ID,
  CONTENT_ID,
  FOOTER_ID,
};
