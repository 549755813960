import { useEffect, useState } from 'react';
import { throttle } from 'lodash';

import { isBrowser } from 'utils/browser';
import { DESKTOP_BREAKPOINT, TABLET_BREAKPOINT } from 'utils/constants';

import { UseScreenRecognitionHook } from './models';

const useScreenRecognition: UseScreenRecognitionHook = () => {
  const browserAvailable = isBrowser();
  const [isMobile, setIsMobile] = useState<null | boolean>(null);
  const [isTablet, setIsTablet] = useState<null | boolean>(null);
  const [isDesktop, setIsDesktop] = useState<null | boolean>(null);
  const [isNotMobile, setIsNotMobile] = useState<null | boolean>(null);
  const [windowWidth, setWindowWidth] = useState<number | false>(0);

  const handleResize = () => {
    const width = browserAvailable && window.innerWidth;

    setWindowWidth(width);
    setIsMobile(width < TABLET_BREAKPOINT);
    setIsNotMobile(width >= TABLET_BREAKPOINT);
    setIsTablet(width >= TABLET_BREAKPOINT && width < DESKTOP_BREAKPOINT);
    setIsDesktop(width >= DESKTOP_BREAKPOINT);
  };

  useEffect(() => {
    const throttledOnResize = throttle(handleResize, 500);

    handleResize();
    if (browserAvailable) {
      window.addEventListener('resize', throttledOnResize);
    }

    return () => window.removeEventListener('resize', throttledOnResize.cancel);
  }, []);

  return {
    isMobile,
    isTablet,
    isDesktop,
    isNotMobile,
    windowWidth,
  };
};

export default useScreenRecognition;
