import React, { FC } from 'react';
import { Link } from 'gatsby';

import IconCustom from 'components/helpers/IconCustom';

import { IPropsMobileSubMenu } from './models';

import './MobileSubMenu.scss';

const MobileSubMenu: FC<IPropsMobileSubMenu> = ({
  className,
  children,
  subMenu,
  handlerReloadPage,
}) => (
  <div data-testid="MobileSubMenu" className={className}>
    <div className="sub-menu-nav-item ">
      <input className="sub-menu-nav-item__input" type="checkbox" id={children} />
      <label className="sub-menu-nav-item__label" htmlFor={children}>
        <span>{children}</span>
        <IconCustom icon="arrow" className="sub-menu-nav-item__icon" />
      </label>
      {subMenu.map(({ url, label }) => (
        <Link
          to={url?.[0].url || '/'}
          key={label}
          className="sub-menu-nav-item__link"
          activeClassName="sub-menu-nav-item__link--active"
          onClick={handlerReloadPage(url?.[0].url || '/')}
        >
          {label}
        </Link>
      ))}
    </div>
  </div>
);
export default MobileSubMenu;
