import React, { ChangeEvent, FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { navigate } from 'gatsby';

import IconCustom from 'components/helpers/IconCustom';
import { ALGOLIA_URL_PARAMS } from 'utils/algoliaFilters/constants';
import { getLocationQueryStringParam } from 'utils/browser';

import { IPropsSearchBar } from './models';

import './SearchBar.scss';

const SearchBar: FC<IPropsSearchBar> = ({
  searchIconFormAriaLabel,
  searchPlaceholder,
  searchRedirect,
  searchTitle,
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const inputValueChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  }, []);

  const queryUrlParam = getLocationQueryStringParam(ALGOLIA_URL_PARAMS.querySearch, 'string');

  useEffect(() => {
    setInputValue(queryUrlParam);
  }, [queryUrlParam]);

  const redirectToSearchResultPage = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      navigate(
        encodeURI(`${searchRedirect[0].url}?${ALGOLIA_URL_PARAMS.querySearch}=${inputValue}`)
      );
    },
    [searchRedirect, inputValue]
  );

  return (
    <div data-testid="SearchBar" className="search-bar">
      <form className="search-bar__form" onSubmit={redirectToSearchResultPage}>
        <input
          value={inputValue}
          onChange={inputValueChange}
          type="text"
          className="search-bar__input"
          placeholder={searchPlaceholder}
          title={searchTitle}
          aria-label={searchTitle}
        />
        <button aria-label={searchIconFormAriaLabel} type="submit" className="search-bar__submit">
          <IconCustom icon="search" className="search-bar__submit-icon" />
        </button>
      </form>
    </div>
  );
};

export default SearchBar;
