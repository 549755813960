import React, { FC, useCallback, useMemo, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Header as HeaderNamespace } from '@shared/types';
import classNames from 'classnames';
import useScreenRecognition from 'hooks/useScreenRecognition';

import Container from 'layout/Container';
import DesktopNavigation from 'layout/Header/DesktopNavigation';
import LanguageSelector from 'layout/Header/LanguageSelector';
import MobileNavigation from 'layout/Header/MobileNavigation';
import MobileSearchBar from 'layout/Header/MobileSearchBar';
import SearchBar from 'layout/Header/SearchBar';
import Logo from 'layout/Logo';
import IconCustom from 'components/helpers/IconCustom';
import { HTML_ELEMENTS } from 'utils/constants';
import findByLang from 'utils/findByLang';

import Helpers from './helpers';
import { IPropsHeader } from './models';

import './Header.scss';

const SearchTriggerId = 'searchTrigger';

const Header: FC<IPropsHeader> = ({ brandSettings, urls, languages, locales, currentLang }) => {
  const {
    allHeaderType: { nodes: headersNodes },
  } = useStaticQuery<{ allHeaderType: { nodes: HeaderNamespace.IStructure[] } }>(graphql`
    {
      allHeaderType {
        nodes {
          ...FragmentHeader
        }
      }
    }
  `);

  const {
    navigation,
    languageSelectorAriaLabel,
    languageSelectorLabel,
    localeSelectorAriaLabel,
    localeSelectorLabel,
    burgerAriaLabel,
    searchIconAriaLabel,
    searchIconFormAriaLabel,
    searchPlaceholder,
    searchRedirect,
    searchTitle,
    homePageLink,
    removeSearchFormAriaLabel,
    closeSearchFormAriaLabel,
  } = headersNodes.find(({ lang }) => findByLang(lang, currentLang))!;

  const [isOpenNavigationMenu, setIsOpenNavigationMenu] = useState<boolean>(false);
  const [isOpenLanguageSelector, setIsOpenLanguageSelector] = useState<boolean>(false);
  const [isOpenLocaleSelector, setIsOpenLocaleSelector] = useState<boolean>(false);
  const [isOpenMobileSearchBar, setIsOpenMobileSearchBar] = useState<boolean>(false);

  const parsedLanguages = useMemo(
    () => Helpers.parseLanguagesToArray(urls, languages),
    [languages, urls]
  );
  const parsedLocales = useMemo(
    () => Helpers.parseLanguagesToArray(urls, locales),
    [locales, urls]
  );

  const handleOpenNavigationMenu = useCallback(() => {
    if (isOpenMobileSearchBar) {
      setIsOpenMobileSearchBar(false);
    }
    setIsOpenNavigationMenu((prev) => !prev);
  }, [isOpenMobileSearchBar]);

  const handleVisibleLanguageSelector = useCallback(
    () => setIsOpenLanguageSelector((prev) => !prev),
    []
  );

  const handleVisibleLocaleSelector = useCallback(
    () => setIsOpenLocaleSelector((prev) => !prev),
    []
  );

  const handleCloseLanguageSelector = useCallback(
    (isOpen: boolean) => () => setIsOpenLanguageSelector(isOpen),
    []
  );

  const handleCloseLocaleSelector = useCallback(
    (isOpen: boolean) => () => setIsOpenLocaleSelector(isOpen),
    []
  );

  const handleMobileSearchBar = useCallback(() => {
    if (isOpenNavigationMenu) {
      setIsOpenNavigationMenu(false);
    }

    setIsOpenMobileSearchBar((prev) => !prev);
  }, [isOpenNavigationMenu]);

  const handlerCloseSearchBar = useCallback(() => setIsOpenMobileSearchBar(false), []);

  const { isDesktop } = useScreenRecognition();

  const selectors = (
    <>
      <LanguageSelector
        handleVisible={handleVisibleLocaleSelector}
        handleClose={handleCloseLocaleSelector}
        isOpen={isOpenLocaleSelector}
        selectorAriaLabel={localeSelectorAriaLabel}
        selectorLabel={localeSelectorLabel}
        currentLang={currentLang}
        items={parsedLocales}
        isShowSelectorLabel={!isDesktop}
        isDesktop={isDesktop}
        className="locale"
      />
      <LanguageSelector
        handleVisible={handleVisibleLanguageSelector}
        handleClose={handleCloseLanguageSelector}
        isOpen={isOpenLanguageSelector}
        selectorAriaLabel={languageSelectorAriaLabel}
        selectorLabel={languageSelectorLabel}
        currentLang={currentLang}
        items={parsedLanguages}
        isShowSelectorLabel
        isDesktop={isDesktop}
        className="language"
      />
    </>
  );

  return (
    <header
      data-testid="Header"
      id={HTML_ELEMENTS.HEADER_ID}
      className={classNames('header', { 'fixed-header ': isOpenNavigationMenu })}
    >
      {isDesktop ? (
        <div className="header__top-wrapper">
          <Container className="header__top">{selectors}</Container>
        </div>
      ) : null}

      <Container className="header__main">
        <div className="header__left">
          <Logo brandSettings={brandSettings} />
          {isDesktop ? (
            <DesktopNavigation navigation={navigation} homePageLink={homePageLink} />
          ) : null}
        </div>
        <div className="header__right">
          {!isDesktop ? (
            <>
              <button
                aria-label={burgerAriaLabel}
                type="button"
                className="header__burger"
                onClick={handleOpenNavigationMenu}
              >
                <span
                  className={classNames('burger-span', { 'burger--open': isOpenNavigationMenu })}
                />
              </button>
              <button
                aria-label={searchIconAriaLabel}
                type="button"
                className="header__search"
                onClick={handleMobileSearchBar}
              >
                <IconCustom id={SearchTriggerId} icon="search" className="header__search-icon" />
              </button>
            </>
          ) : null}
          {isDesktop ? (
            <div className="header__search-wrapper">
              <SearchBar
                searchTitle={searchTitle}
                searchRedirect={searchRedirect}
                searchPlaceholder={searchPlaceholder}
                searchIconFormAriaLabel={searchIconFormAriaLabel}
                isDesktop={isDesktop}
              />
            </div>
          ) : null}
        </div>
      </Container>

      {!isDesktop ? (
        <div className="mobile-navigation__bottom">
          <MobileNavigation
            isOpenNavigationMenu={isOpenNavigationMenu}
            navigation={navigation}
            selectors={selectors}
          />
        </div>
      ) : null}

      {!isDesktop && isOpenMobileSearchBar ? (
        <div className="mobile-searchbar__bottom">
          <MobileSearchBar
            searchTitle={searchTitle}
            searchRedirect={searchRedirect}
            searchPlaceholder={searchPlaceholder}
            searchIconFormAriaLabel={searchIconFormAriaLabel}
            closeSearchFormAriaLabel={closeSearchFormAriaLabel}
            removeSearchFormAriaLabel={removeSearchFormAriaLabel}
            handleVisibility={handleMobileSearchBar}
            handleCloseSearchBar={handlerCloseSearchBar}
            clickOutsideExceptionsIds={[SearchTriggerId]}
          />
        </div>
      ) : null}
    </header>
  );
};

export default Header;
