import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import loadable from '@loadable/component';
import { UmbracoBrandSettings, UmbracoSiteSettings } from '@shared/types';
import classNames from 'classnames';

import 'styles/main.scss';
import './Layout.scss';

import Footer from 'layout/Footer';
import Header from 'layout/Header';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Loadable from 'components/helpers/Loadable';
import { HTML_ELEMENTS } from 'utils/constants';
import findByLang from 'utils/findByLang';

import { IPropsLayout } from './models';

const PreviewWidget = loadable(() => import('components/helpers/PreviewWidget'));

const Layout: FC<IPropsLayout> = ({
  children,
  className,
  seo,
  urls,
  pageSchemaType,
  isHideHeader,
  lang,
}) => {
  const {
    allBrandSettings: { nodes: brandSettingsNodes },
    allSiteSettings: { nodes: siteSettingsNodes },
  } = useStaticQuery<{
    allBrandSettings: { nodes: UmbracoBrandSettings.IStructure[] };
    allSiteSettings: { nodes: UmbracoSiteSettings.IStructure[] };
  }>(graphql`
    {
      allBrandSettings {
        nodes {
          ...FragmentBrandSettings
        }
      }
      allSiteSettings {
        nodes {
          ...FragmentSiteSettings
        }
      }
    }
  `);

  const brandSettings = brandSettingsNodes.find((item) => findByLang(item.lang, lang || ''))!;
  const siteSettings = siteSettingsNodes.find((item) => findByLang(item.lang, lang || ''))!;
  const shouldLoadPreviewWidget = Boolean(Number(process.env.GATSBY_ENABLE_PREVIEW_WIDGET));

  return (
    <div data-testid="Layout" className={classNames('layout', className)}>
      <Loadable isActive={shouldLoadPreviewWidget}>
        <PreviewWidget cmsUrl={`${process.env.GATSBY_UMBRACO_BASE_URL}/umbraco`} />
      </Loadable>
      {seo && urls?.length ? (
        <>
          <Seo seo={seo} urls={urls} lang={lang} />
          <PageSchema
            type={pageSchemaType || 'WebPage'}
            name={seo.title}
            data={{
              metaTitle: seo.title,
              metaDescription: seo.description,
              metaKeywords: seo.keywords,
            }}
          />
        </>
      ) : null}

      <a
        className="sr-only"
        href={`#${HTML_ELEMENTS.CONTENT_ID}`}
        // eslint-disable-next-line  jsx-a11y/no-interactive-element-to-noninteractive-role
        role="navigation"
        aria-label={siteSettings.skipToMainContentText}
      >
        {siteSettings.skipToMainContentText}
      </a>
      {!isHideHeader && urls?.length ? (
        <Header
          brandSettings={brandSettings}
          searchPlaceholder={siteSettings.searchPlaceholder}
          searchTitle={siteSettings.searchTitle}
          urls={urls.map((item) => [item.lang, item.href])}
          languages={siteSettings?.languages}
          locales={siteSettings.locales}
          currentLang={siteSettings.lang}
        />
      ) : null}

      <main id={HTML_ELEMENTS.CONTENT_ID} className="main">
        {children}
      </main>

      <Footer brandSettings={brandSettings} currentLang={siteSettings.lang} />
    </div>
  );
};

export default Layout;
