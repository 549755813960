import React, { FC, useCallback } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import MobileSubMenu from 'layout/Header/MobileSubMenu';

import { IPropsMobileNavigation } from './models';

import './MobileNavigation.scss';

const MobileNavigation: FC<IPropsMobileNavigation> = ({
  isOpenNavigationMenu,
  navigation,
  selectors,
}) => {
  const handlerReloadPage = useCallback(
    (link: string) => () => {
      if (window.location.href.includes(link)) {
        setTimeout(() => window.location.reload(), 100);
      }
    },
    []
  );

  return (
    <div
      data-testid="MobileNavigation"
      className={classNames('mobile-navigation', {
        'mobile-navigation--open': isOpenNavigationMenu,
      })}
    >
      <div
        className={classNames('mobile-navigation__links', {
          'mobile-navigation__links--open': isOpenNavigationMenu,
        })}
      >
        {navigation.map(({ navigationWithSubMenu, label, url }) => {
          if (navigationWithSubMenu) {
            return (
              <MobileSubMenu
                key={label}
                className="mobile-navigation__nav-item"
                subMenu={navigationWithSubMenu}
                handlerReloadPage={handlerReloadPage}
              >
                {label}
              </MobileSubMenu>
            );
          }
          if (url) {
            return (
              <Link
                to={url[0].url}
                key={label}
                className={classNames('mobile-navigation__nav-item ', {
                  'mobile-navigation__nav-item--open': isOpenNavigationMenu,
                })}
                activeClassName="mobile-navigation__nav-item--active"
                onClick={handlerReloadPage(url[0].url)}
              >
                <span className="nav-item-label">{label}</span>
              </Link>
            );
          }

          return null;
        })}
      </div>

      <div
        className={classNames('mobile-navigation__selectors', {
          'mobile-navigation__selectors--open': isOpenNavigationMenu,
        })}
      >
        {selectors}
      </div>
    </div>
  );
};

export default MobileNavigation;
