import { BrandInfo, Page } from '../models';

const extractor = (
  pageHref: string,
  { author }: Page,
  { lang, brandName, siteUrl, sameAs }: BrandInfo
): object => {
  const url = new URL(pageHref);

  return {
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': pageHref,
    },
    inLanguage: lang,
    author: {
      '@type': 'Person',
      name: author || brandName,
    },
    publisher: {
      '@type': 'Organization',
      name: brandName,
      url: siteUrl,
      sameAs,
      logo: {
        '@type': 'ImageObject',
        url: `${url.origin}/logo.png`,
      },
    },
  };
};

export default extractor;
