import React, { FC } from 'react';

import { IDangerouslySetInnerHtml } from './models';

const DangerouslySetInnerHtml: FC<IDangerouslySetInnerHtml> = ({
  html,
  element,
  refObj,
  ...rest
}) => {
  const Tag = element || 'div';

  return html ? (
    <Tag
      data-testid="DangerouslySetInnerHtml"
      data-rte="true"
      dangerouslySetInnerHTML={{ __html: html }}
      ref={refObj || undefined}
      {...rest}
    />
  ) : null;
};

export default DangerouslySetInnerHtml;
