import React, { FC } from 'react';

import DesktopNavItem from '../DesktopNavItem';
import { IPropsDesktopNavigation } from './models';

import './DesktopNavigation.scss';

const DesktopNavigation: FC<IPropsDesktopNavigation> = ({ navigation, homePageLink }) => (
  <ul data-testid="DesktopNavigation" className="desktop-nav">
    {navigation?.length
      ? navigation.map((element) => (
          <DesktopNavItem key={element.label} navItem={element} homePageLink={homePageLink} />
        ))
      : null}
  </ul>
);

export default DesktopNavigation;
