import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import useClickOutside from 'hooks/useClickOutside';

import IconCustom from 'components/helpers/IconCustom';

import { IPropsDesktopSubNav } from './models';

import './DesktopNavItem.scss';

const DesktopNavItem: FC<IPropsDesktopSubNav> = ({
  navItem: { navigationWithSubMenu, label, url, ariaLabel },
  homePageLink,
}) => {
  const [openSubMenu, setOpenSubMenu] = useState<string | null>(null);
  const [activeButtonLink, setActiveButtonLink] = useState<boolean>(false);
  const navItemRef = useRef(null);

  const handleNavigationItemClick = useCallback(
    (id: string | null) => () => {
      setOpenSubMenu(id === openSubMenu ? null : id);
    },
    [openSubMenu]
  );

  useEffect(() => {
    const currentURL = window.location.href;

    navigationWithSubMenu?.forEach(({ url: urlItem }) => {
      if (urlItem?.[0]?.url && currentURL.toLowerCase().includes(urlItem[0].url.toLowerCase())) {
        setActiveButtonLink(true);
      }
    });
  }, []);

  useClickOutside(navItemRef, handleNavigationItemClick(null));

  return (
    <li
      data-testid="DesktopNavItem"
      ref={navigationWithSubMenu ? navItemRef : null}
      className="desktop-nav__li"
      onMouseEnter={handleNavigationItemClick(label)}
      onMouseLeave={handleNavigationItemClick(null)}
    >
      {navigationWithSubMenu && navigationWithSubMenu?.length >= 1 ? (
        <>
          <button
            className={classNames('desktop-nav__link desktop-nav__link--with-corner', {
              'desktop-nav__link--active': activeButtonLink,
            })}
            type="button"
            aria-label={ariaLabel}
          >
            <span>{label}</span>
            <IconCustom
              icon="arrow"
              className={classNames('desktop-nav__icon', {
                [`desktop-nav__icon--close`]: openSubMenu && openSubMenu === label,
              })}
            />
          </button>
          <ul
            className={classNames('desktop-nav__sub-menu', {
              [`desktop-nav__sub-menu--open`]: openSubMenu && openSubMenu === label,
            })}
          >
            {navigationWithSubMenu.map(({ label: subLabel, url: subUrl }) => (
              <li key={subLabel} className="sub-menu__li">
                <Link
                  activeClassName="sub-menu__link--active"
                  className="sub-menu__link"
                  to={subUrl?.[0].url || '/'}
                  partiallyActive
                >
                  {subLabel}
                </Link>
              </li>
            ))}
          </ul>
        </>
      ) : (
        <Link
          activeClassName="desktop-nav__link--active"
          className="desktop-nav__link"
          to={url?.[0].url || '/'}
          partiallyActive={url?.[0].url !== homePageLink?.[0].url}
        >
          {label}
        </Link>
      )}
    </li>
  );
};

export default DesktopNavItem;
