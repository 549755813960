import React, { FC } from 'react';
import classNames from 'classnames';

import { IconCustomProps } from './models';
import './IconCustom.scss';

const IconCustom: FC<IconCustomProps> = ({ icon, className, id }) => (
  <span
    data-testid="IconCustom"
    className={classNames('icon', className, { [`icon-${icon}`]: icon })}
    {...(id ? { id } : {})}
  />
);

export default IconCustom;
