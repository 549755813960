import React, { FC } from 'react';
import { Link } from 'gatsby';

import { IPropsNavigationItems } from './models';

import './NavigationItems.scss';

const NavigationItems: FC<IPropsNavigationItems> = ({ navigationItems }) => (
  <ul data-testid="NavigationItems" className="navigation-items">
    {navigationItems?.length
      ? navigationItems.map((element) => (
          <Link
            key={element.label}
            className="navigation-items__link"
            to={element.url?.[0].url || '/'}
          >
            {element.label}
          </Link>
        ))
      : null}
  </ul>
);

export default NavigationItems;
