import React, { FC, useMemo, useRef } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import useClickOutside from 'hooks/useClickOutside';

import DangerouslySetInnerHtml from 'components/helpers/DangerouslySetInnerHtml';
import IconCustom from 'components/helpers/IconCustom';

import { IPropsLanguageSelector } from './models';

import './LanguageSelector.scss';

const LanguageSelector: FC<IPropsLanguageSelector> = ({
  handleVisible,
  handleClose,
  isOpen,
  selectorAriaLabel,
  selectorLabel,
  items,
  currentLang,
  isShowSelectorLabel,
  isDesktop,
  className,
}) => {
  const languageSelectorRef = useRef<HTMLDivElement | null>(null);

  useClickOutside(languageSelectorRef, handleClose(false));

  const currentData = useMemo(
    () => items.find((item) => item.languageLetters === currentLang),
    [items, currentLang]
  );

  return items.length ? (
    <div
      data-testid="LanguageSelector"
      className={`language-selector language-selector-${className}`}
    >
      {isShowSelectorLabel && selectorLabel ? (
        <div className="language-selector__selectorLabel">{selectorLabel}</div>
      ) : null}
      <div
        className="language-selector__dropdown"
        ref={languageSelectorRef}
        onMouseEnter={isDesktop ? handleClose(true) : undefined}
        onMouseLeave={isDesktop ? handleClose(false) : undefined}
      >
        <button
          type="button"
          className={classNames('language-selector__button', {
            'language-selector__button--open': isOpen,
          })}
          aria-label={selectorAriaLabel}
          onClick={handleVisible}
        >
          <span>{currentData?.languageLabel}</span>
          <IconCustom
            icon="play3"
            className={classNames('language-selector__icon', {
              'language-selector__icon--open': isOpen,
            })}
          />
        </button>
        <div
          className={classNames('language__categories', {
            'language__categories--open': isOpen,
          })}
        >
          {items?.map(({ languageLabel, link, languageLetters, languageLettersShort }) => (
            <Link
              key={languageLabel}
              className={classNames('language__category', {
                'language__category--current': currentLang === languageLetters,
              })}
              to={link || '/'}
            >
              <DangerouslySetInnerHtml
                className="language__category-title"
                html={languageLettersShort}
              />
            </Link>
          ))}
        </div>
      </div>
    </div>
  ) : null;
};

export default LanguageSelector;
