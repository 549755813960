import React, { FC } from 'react';
import Img from 'gatsby-image/withIEPolyfill';

import DangerouslySetInnerHtml from 'components/helpers/DangerouslySetInnerHtml';
import useScreenRecognition from 'hooks/useScreenRecognition';

import { IGatsbyImageComponentProps } from './models';
import './GatsbyImage.scss';

const GatsbyImage: FC<IGatsbyImageComponentProps> = ({
  isLazyLoading = true,
  alt = '',
  className = '',
  objectFit,
  objectPosition,
  image,
  useFocalPoint,
  ...restProps
}) => {
  const { isNotMobile } = useScreenRecognition();
  const padY = parseInt(image?.focalPoint?.y || '50', 10);
  const padX = parseInt(image?.focalPoint?.x || '50', 10);
  const svgRest: { [key: string]: any } = restProps;
  const isSvg = image?.svg?.content;
  const loading = isLazyLoading ? 'lazy' : 'eager';

  const imageToRender = isNotMobile
    ? image?.gatsbyImage
    : image?.mobileImage
    ? image?.mobileImage
    : image?.gatsbyImage;

  const imageProps = {
    className,
    alt: image?.altText || alt,
    objectFit: objectFit || 'cover',
    objectPosition: useFocalPoint ? `${padX}% ${padY}%` : objectPosition || '50% 50%',
    ...restProps,
  };

  return (
    <>
      {isSvg ? (
        <DangerouslySetInnerHtml
          data-testid="GatsbyImage-svg"
          html={image.svg?.content}
          element="div"
          className={`gatsby-svg-image ${className}`}
          {...svgRest}
        />
      ) : null}
      {!isSvg && imageToRender?.childImageSharp?.fixed ? (
        <Img
          data-testid="GatsbyImage-fixed"
          {...imageProps}
          fixed={imageToRender.childImageSharp.fixed}
          loading={loading}
        />
      ) : null}
      {!isSvg && imageToRender?.childImageSharp?.fluid ? (
        <Img
          data-testid="GatsbyImage-fluid"
          {...imageProps}
          fluid={imageToRender.childImageSharp.fluid}
          loading={loading}
        />
      ) : null}
    </>
  );
};

export default GatsbyImage;
